<template>
  <b-row>

    <b-col
      v-if="documentTypes.length"
      md="12"
    >
      <b-card
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1 ml-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                v-b-modal.add-modal
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                class="float-right mb-1"
              >
                Créer une pièce
                <feather-icon icon="PlusCircleIcon" />
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="documentTypes"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Modifier une pièce'"
                v-b-modal.update-modal
                variant="primary"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="getDocumentType(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Supprimer une pièce'"
                v-b-modal.suppression
                variant="danger"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="getDocumentType(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>

            </div>
          </template></b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="documentTypes.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-col
      v-else
      md="12"
    >
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1 ml-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                v-b-modal.add-modal
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                class="float-right mb-1"
              >
                Créer une pièce
                <feather-icon icon="PlusCircleIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-body>
          <b-row>
            <b-col md="6">
              <b-img
                :src="require('@/assets/images/original.png')"
                class="shadow"
                style="height:200px !important"
                alt=""
                srcset=""
              />
            </b-col>
            <b-col
              md="6"
              class="mt-5"
            >
              <span class="text-center">
                Nous n'avons pas trouvé de résultats à afficher
              </span>
            </b-col>
          </b-row>

        </b-card-body>

      </b-card>
    </b-col>
    <b-modal
      id="add-modal"
      ref="my-modal"
      centered
      no-close-on-backdrop
      button-size="sm"
      title="Créer une pièce"
      :busy="documentTypeProcess"
      ok-title="Créer"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @show="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="ValidationRules">
        <form @submit.prevent="handleSubmitModal">

          <b-form-group
            label="Nom"
            label-for="label"
          >
            <validation-provider
              #default="{ errors }"
              name="nom"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="form.label"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Nom"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="description">Description</label>
            <b-form-textarea
              id="description"
              v-model="form.description"
              placeholder="Description"
              rows="3"
            />
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="update-modal"
      ref="my-modal"
      centered
      no-close-on-backdrop
      button-size="sm"
      title="Modifier une pièce"
      :busy="documentTypeProcess"
      ok-title="Modifier"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @ok="handleOk"
    >
      <validation-observer ref="ValidationRules">
        <form @submit.prevent="handleSubmitModal">

          <b-form-group
            label="Nom"
            label-for="label"
          >
            <validation-provider
              #default="{ errors }"
              name="nom"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="formUpdate.label"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Nom"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="description">Description</label>
            <b-form-textarea
              id="description"
              v-model="formUpdate.description"
              placeholder="Description"
              rows="3"
            />
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="suppression"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Supprimer"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleDeleteOk"
    >
      <form @submit.prevent="handleDeleteSubmitModal">
        <h6>Etes-vous sûr de vouloir supprimer ce type de pièce?</h6>

      </form>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted, reactive } from '@vue/composition-api'
import { required } from '@validations'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput,
  BSpinner, VBTooltip, VBModal, BModal, BFormTextarea, BImg,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useDocumentTypes from '@/services/admin/documentTypeService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,

    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    // eslint-disable-next-line no-undef
    const {
      documentTypes, loader, listDocumentTypesByMairie, documentTypeProcess, documentTypeSuccess, storeOrUpdateDocumentType, deleteDocumentType,
    } = useDocumentTypes()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'label', label: 'Nom', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      label: '',
      description: '',
    })
    const formUpdate = reactive({
      id: null,
      label: '',
      description: '',
    })
    const resetModal = () => {
      formUpdate.id = null
      formUpdate.label = ''
      formUpdate.description = ''
      form.label = ''
      form.description = ''
    }

    onMounted(async () => {
      await listDocumentTypesByMairie()
      // Set the initial number of items
      totalRows.value = documentTypes.value.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    // Obtenir une pièce
    const getDocumentType = async item => {
      formUpdate.label = item.label
      formUpdate.description = item.description
      formUpdate.id = item.id
    }
    const store = async () => {
      if (formUpdate.id !== null) storeOrUpdateDocumentType({ ...formUpdate })
      else storeOrUpdateDocumentType({ ...form })

      if (documentTypeSuccess.value) resetModal(); await listDocumentTypesByMairie()
    }
    const deleteOneDocumentType = async () => {
      await deleteDocumentType(formUpdate.id)
      resetModal()
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      fields,
      onFiltered,
      form,
      documentTypeProcess,
      documentTypeSuccess,
      formUpdate,
      getDocumentType,
      formatter,
      store,
      loader,
      documentTypes,
      deleteOneDocumentType,
      resetModal,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleDeleteOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleDeleteSubmitModal()
    },

    async handleDeleteSubmitModal() {
      // Exit when the form isn't valid
      await this.deleteOneDocumentType()
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.store()
          if (this.documentTypeSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
  },

}

</script>
