import { ref } from '@vue/composition-api'
import router from '@/router'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useDocumentTypes() {
  const { errorToast, successToast } = useAlertNotification()

  const documentType = ref([])
  const loader = ref(false)
  const documentTypeProcess = ref(false)
  const documentTypeSuccess = ref(false)
  const documentTypes = ref([])
  const errors = ref('')

  // Liste des DocumentTypes
  const getListeDocumentTypes = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-list-type-documents')
      if (response.data.success === true) {
        loader.value = false
        documentTypes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  // Liste des DocumentTypes
  const listDocumentTypesByMairie = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-type-document-by-mairie')
      if (response.data.success === true) {
        loader.value = false
        documentTypes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  // Trouver une documentType par son Id
  const findDocumentTypeById = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-type-document-by-id/${router.currentRoute.params.id}`)
      if (response.data.success === true) {
        loader.value = false
        documentType.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  // Créer ou Modifier une documentType
  const storeOrUpdateDocumentType = async data => {
    try {
      errors.value = ''
      documentTypeProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('id')) {
        const response = await axiosClient.patch(`/update-type-document/${data.id}`, data)
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          documentTypeSuccess.value = true
          documentTypeProcess.value = false
          await listDocumentTypesByMairie()
        }
      } else {
        const response = await axiosClient.post('/create-type-document', data)
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          documentTypeSuccess.value = true
          documentTypeProcess.value = false
          await listDocumentTypesByMairie()
        }
      }
    } catch (error) {
      documentTypeProcess.value = false
      documentTypeSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorToast('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const deleteDocumentType = async id => {
    try {
      errors.value = ''
      documentTypeProcess.value = true
      const response = await axiosClient.delete(`/delete-type-document/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)
        documentTypeSuccess.value = true
        documentTypeProcess.value = false
        await listDocumentTypesByMairie()
      }
    } catch (error) {
      documentTypeProcess.value = false
      documentTypeSuccess.value = false
      loader.value = false
      errors.value = ''
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }
  return {
    errors,
    documentType,
    documentTypes,
    documentTypeProcess,
    listDocumentTypesByMairie,
    loader,
    getListeDocumentTypes,
    findDocumentTypeById,
    storeOrUpdateDocumentType,
    documentTypeSuccess,
    deleteDocumentType,
  }
}
